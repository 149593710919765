import React from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <span>I am </span>
          <Highlight>Virochaan Ravichandran Gowri</Highlight>
          <span> an 18 year old </span>  
          <Highlight>Software Engineer</Highlight>
          <span> and </span> 
          <Highlight>Developer</Highlight> 
          <span> from Toronto. I am currently
          studying Software Engineering at the McMaster University. I have 
          always enjoyed the process of developing solutions to novel problems 
          and implementing this through code. I have experience working in a variety of
          different disciplines within software development, including </span>
          <Highlight>Machine Learning</Highlight>
          <span>, </span> 
          <Highlight>Data analytics</Highlight>
          <span> and currently I am focusing on creating </span> 
          <Highlight>Full-Stack Web Applications</Highlight>.
        </blockquote>
      </Card.Body>
    </Card>
  );
}

const Highlight = styled.span`
    text-decoration: underline;
    color: #ffb742;
    font-weight: 600;
`

export default AboutCard;
